import { gql, useApolloClient } from "@apollo/client";
import { navigate } from 'gatsby';
import { useEffect } from "react";

const LOCATION_QUERY = gql`
    query GetLocation {
        location @client{
        page
    }
}`;

const IndexPage = () => {
  const globalState = useApolloClient()
  useEffect(() => {
    globalState.query({query: LOCATION_QUERY}).then((result:any) => {
      let redirect = '/get-started/introduction'
      if(typeof window !== `undefined`) {
        redirect = window?.localStorage.getItem('prevPath') || redirect;
    }
      navigate(redirect,{replace:true})})
  },[])

  return null
}

export default IndexPage
